<div class="category-boxes">
    <ul class="col-md-12 row" style="padding-top: 15px">
        <li class="col-md-4" style="padding-bottom: 30px">
            <a [routerLink]="['wyniki_badan_surowca']" title="">
                <span class="box-icon">
                    <img src="assets/icons/wyniki_badan_surowca.svg" alt="">
                </span>
                <span class="box-title">
                    <span class="box-title-wrapper">
                        Wyniki badań Surowca
                    </span>
                </span>
            </a>
        </li>
        <li class="col-md-4" style="padding-bottom: 30px">
            <a [routerLink]="['badania_za_okres']" title="">
                <span class="box-icon">
                    <img src="assets/icons/badania_za_okres.svg" alt="">
                </span>
                <span class="box-title">
                    <span class="box-title-wrapper">
                        Badania za okres
                    </span>
                </span>
            </a>
        </li>
        <li class="col-md-4" style="padding-bottom: 30px">
            <a [routerLink]="['dostawy_surowca']" title="">
                <span class="box-icon">
                    <img src="assets/icons/dostawy_surowca.svg" alt="">
                </span>
                <span class="box-title">
                    <span class="box-title-wrapper">
                        Dostawy surowca
                    </span>
                </span>
            </a>
        </li>
        <li class="col-md-4" style="padding-bottom: 30px">
            <a [routerLink]="['historia_dostaw']" title="">
                <span class="box-icon">
                    <img src="assets/icons/rozliczenie_dostaw.svg" alt="">
                </span>
                <span class="box-title">
                    <span class="box-title-wrapper">
                        Rozliczenie dostaw
                    </span>
                </span>
            </a>
        </li>
        <li class="col-md-4" style="padding-bottom: 30px">
            <a [routerLink]="['rejestr_potracen']" title="">
                <span class="box-icon">
                    <img src="assets/icons/rejestr_potracen.svg" alt="">
                </span>
                <span class="box-title">
                    <span class="box-title-wrapper">
                        Rejestr Potrąceń
                    </span>
                </span>
            </a>
        </li>
        <li class="col-md-4" style="padding-bottom: 30px">
            <a [routerLink]="['dokumenty']" title="">
                <span class="box-icon">
                    <img src="assets/icons/dokumenty.svg" alt="">
                </span>
                <span class="box-title">
                    <span class="box-title-wrapper">
                        Dokumenty
                    </span>
                </span>
            </a>
        </li>
    </ul>
    <div class="clear"></div>
</div>
