import { Label } from 'ng2-charts';
import { Component, OnInit, EmbeddedViewRef, ComponentFactoryResolver, ApplicationRef, Injector } from '@angular/core';
import { RestService } from '../services/rest.service';
import { HistoriaDostaw } from '../models/historia.dostaw';
import { AuthenticationService } from '../services/authentication.service';
import { ChartDataSets } from 'chart.js';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from 'saturn-datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material';
import { PdfTopComponent } from '../pdf-top/pdf-top.component';
// tslint:disable-next-line:no-duplicate-imports


export interface Years {
  value: string;
  viewValue: string;
}

export interface Months {
  value: string;
  viewValue: string;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM.YYYY',
  },
  display: {
    dateInput: 'MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-historia-dostaw',
  templateUrl: './historia-dostaw.component.html',
  styleUrls: ['./historia-dostaw.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class HistoriaDostawComponent implements OnInit {

  displayedColumns: string[] = ['data_dostawy', 'litry', 'proc_t', 'sc_netto', 'netto', 'brutto', 'nr_fakt'];
  dostawyList: HistoriaDostaw[] = [];
  startDate = new FormControl(moment().subtract(1, 'year'));
  endDate = new FormControl(moment());
  selectedYear = new Date().getFullYear().toString();
  chartData: ChartDataSets[] = [];
  chartLabels: Label[] = [];
  sumDeliveryCharge = 0;
  sumMilk = 0;


  constructor(
    private restApiService: RestService,
    private authenticationService: AuthenticationService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this.getInitData();
  }

  ngOnInit() {
  }

  setChartData(data: HistoriaDostaw[]) {
    this.chartData = [];
    this.chartLabels = [];
    // let tmpDataLiter = [];
    // let tmpDataPrice = [];
    const tmpLabels = [];
    const tmpDataLiter = data.map(obj => obj.litry);
    const tmpDataPrice = data.map(obj => obj.sc_netto);
    this.chartLabels = data.map(obj => obj.data_dostawy);
    // console.log(test);
    /*data.forEach(obj => {
      tmpDataLiter.push(obj.litry);
      tmpDataPrice.push(obj.sc_netto);
      this.chartLabels.push(obj.data_dostawy);
    });*/
    this.chartData.push({
      data: tmpDataLiter,
      label: 'Litry mleka'
    });
    this.chartData.push({
      data: tmpDataPrice,
      label: 'Średnia cena',
      type: 'line',
      yAxisID: 'y-axis-1'
    });
    // console.log(this.chartData)

  }
  chosenYearHandler(normalizedYear: moment.Moment, form) {
    const ctrlValue = form.value;
    ctrlValue.year(normalizedYear.year());
    form.setValue(ctrlValue);
  }
  chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>, form) {
    const ctrlValue = form.value;
    ctrlValue.month(normalizedMonth.month());
    form.setValue(ctrlValue);
    datepicker.close();
    this.getInitData();
  }
  getInitData() {
    let p_nr_dostawcy = this.authenticationService.getNrDostawcy();
    const p_okres_od = this.startDate.value.format('MM.YYYY');
    const p_okres_do = this.endDate.value.format('MM.YYYY');
    if (p_nr_dostawcy) {
      const body = {
        p_nr_dostawcy,
        p_okres_od,
        p_okres_do
      };
      this.restApiService.callRestApi('/rpc/get_historia_dostaw', body)
        .subscribe(
          (data: HistoriaDostaw[]) => {
            this.dostawyList = data;
            this.sumHistory(data);
            this.setChartData(data);
          },
          error => {
            console.log(error);
          });
    }
  }
  sumHistory(data: HistoriaDostaw[]) {
    this.sumMilk = 0;
    this.sumDeliveryCharge = 0;
    data.forEach(element => {
      this.sumDeliveryCharge += element.brutto;
      this.sumMilk += element.litry;
    });
  }
  public captureScreen() {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(PdfTopComponent)
      .create(this.injector);
    componentRef.instance.okres = (this.startDate && this.endDate) ? this.startDate + ' - ' + this.endDate : this.selectedYear;
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    const data = document.getElementById('contentToConvert');
    const clone =  data.cloneNode(true) as HTMLElement;
    clone.insertBefore(domElem, clone.firstChild);
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(document.head.innerHTML);
    WindowPrt.document.write(clone.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
  }

}
