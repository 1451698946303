<div class="row" *ngIf="!mobileQuery.matches">
    <div class="fixed-pos wcag">
        <button class="btn normal"  (click)="normalFont()"></button>
        <button class="btn font-big"  (click)="largerFont()"></button>
        <button class="btn font-large"  (click)="largestFont()"></button>
        <button class="btn contrast"  (click)="contrast()"></button>
    </div>

</div>
<router-outlet ></router-outlet>
