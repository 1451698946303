import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeStyleService {

  constructor() { }

  getFontSize(style: string): boolean {
    if (localStorage.getItem(style) == 'true') {
      return true;
    } else {
      return false;
    }
  }
  getContrast(): boolean {
    if (localStorage.getItem('contrast') == 'true') {
      return true;
    } else {
      return false;
    }
  }
}
