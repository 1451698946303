import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest.service';
import { AuthenticationService } from '../services/authentication.service';
import { Dokumenty } from '../models/dokumenty';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-dokumenty',
  templateUrl: './dokumenty.component.html',
  styleUrls: ['./dokumenty.component.scss']
})
export class DokumentyComponent implements OnInit {
  dokumentyList: Dokumenty[] = [];
  imageBlobUrl;
  pdfResult: any;
  fileUrl: any;
  constructor(
    private restApiService: RestService,
    private authenticationService: AuthenticationService,
    private domSanitizer: DomSanitizer
  ) {
    this.getInitData();
  }

  ngOnInit() {
  }
  getInitData() {
    let p_nr_dostawcy = this.authenticationService.getNrDostawcy();
    if (p_nr_dostawcy) {
      const body = {
        p_nr_dostawcy
      };
      this.restApiService.callRestApi('/rpc/get_dokumenty_lista', body)
        .subscribe(
          (data: Dokumenty[]) => {
            this.dokumentyList = data;
          },
          error => {
            console.log(error);
          });
    }
  }

  download_file(id: string, nazwa_pliku:string) {
    let p_nr_dostawcy = this.authenticationService.getNrDostawcy();
    let p_nr_id = id;
    if (p_nr_dostawcy) {
      const body = {
        p_nr_dostawcy,
        p_nr_id
      };
      this.restApiService.downloadFile('/rpc/get_dokument_blob', body)
      .subscribe((resp) => {
         this.createImageFromBlob(resp, nazwa_pliku);
        // tslint:disable-next-line: max-line-length
        // console.log(this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(resp)).changingThisBreaksApplicationSecurity);
        /*window.open(
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(resp)).changingThisBreaksApplicationSecurity,'_blank'
          );*/
      },
      error => {
        console.log(error);
      });
    }
  }
  createImageFromBlob(file: Blob,nazwa_pliku:string) {
    var FileSaver = require('file-saver');

    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageBlobUrl = reader.result;
      FileSaver.saveAs(reader.result, nazwa_pliku+".pdf");
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }
}
