import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest.service';
import { HistoriaDostaw } from '../models/historia.dostaw';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-rejest-potracen',
  templateUrl: './rejest-potracen.component.html',
  styleUrls: ['./rejest-potracen.component.scss']
})
export class RejestPotracenComponent implements OnInit {

  displayedColumns: string[] = ['sksk_nazwa', 'wartosc_potr', 'rata', 'data_rozp', 'splata', 'saldo', 'rata_biezaca'];
  dostawyList: HistoriaDostaw[] = [];

  constructor(
    private restApiService: RestService,
    private authenticationService: AuthenticationService
  ) {
    this.getInitData();
  }

  ngOnInit() {
  }

  getInitData() {
    let p_nr_dostawcy = this.authenticationService.getNrDostawcy();
    if (p_nr_dostawcy) {
      const body = {
        p_nr_dostawcy
      };
      this.restApiService.callRestApi('/rpc/get_rejestr_potracen', body)
        .subscribe(
          (data: HistoriaDostaw[]) => {
            this.dostawyList = data;
          },
          error => {
            console.log(error);
          });
    }
  }

}
