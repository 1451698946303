import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ReCaptcha2Component } from 'ngx-captcha';
import { ChangeStyleService } from '../services/change.style.service';
import { RestService } from '../services/rest.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswdForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  public readonly siteKey = '6Lfn-DIUAAAAACBWhJ4D1Qk54RwpFcUop657yioR';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  linkSended: boolean = false;
  public useGlobalDomain: boolean = false;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'pl';
  public type: 'image' ;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private rest: RestService,
    public style: ChangeStyleService
  ) { }

  ngOnInit() {
    this.resetPasswdForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.email, Validators.required])] ,
      recaptcha: ['', Validators.required]
    });


  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }
  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('There is no response - have you submitted captcha?');
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }


  get f() { return this.resetPasswdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswdForm.invalid) {
      return;
    }
    let body ={
      email: this.f.email.value
    };
    this.loading = true;
    this.rest.callRestApi('/rpc/reset_password', body)
      .subscribe(
        data => {
          this.linkSended = true;
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 8000);
        },
        error => {
          this.error = error.error.message;
          this.loading = false;
        });
  }

}
