import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class RestService {
    private currentUserSubject: BehaviorSubject<User>;
    private url = 'http://212.106.129.242:3000';
    public currentUser: Observable<User>;
    helper = new JwtHelperService();


    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    callRestApi(functionName: string, functionBody: object) {
        return this.http.post<any>(this.url + functionName, functionBody)
            .pipe(map(response => {
                // login successful if there's a jwt token in the response
                return response;
            }));
    }

    downloadFile(functionName: string, functionBody: object) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/octet-stream'
          });
        const options = { responseType: 'blob'  };
        return this.http.post<Blob>(this.url + functionName, functionBody ,  {headers: headers, responseType: 'blob' as 'json' });
    }
}
