<div class="container">
  <mat-toolbar class="py-4 color">
    <span class="title-center">Wyniki badań surowca</span>
  </mat-toolbar>

    <div  class="col-md-12 row">
      <mat-form-field class="col-xs-3">
        <input matInput placeholder="Wybierz okres" [satDatepicker]="picker" (dateChange)="changeDataRange($event)"
          [value]="date" (ngModel)="date">
        <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
        <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
      </mat-form-field>
      <button (click)="captureScreen()" mat-button class="col-xs-9" style="margin-right: 0;margin-left: auto;">
        <mat-icon>print</mat-icon>
      </button>
    </div>

  <div id="contentToConvert" #table>
    <table mat-table [dataSource]="badaniaList" class="mat-elevation-z8" style="margin: 2% 0">

      <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="data_badania">
        <th mat-header-cell *matHeaderCellDef> Data badania </th>
        <td mat-cell *matCellDef="let element"> {{element.data_badania | date: 'dd.MM.yyyy' }} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="proc_t">
        <th mat-header-cell *matHeaderCellDef> % tłuszczu </th>
        <td mat-cell *matCellDef="let element"> {{element.proc_t}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="proc_b">
        <th mat-header-cell *matHeaderCellDef> % białka </th>
        <td mat-cell *matCellDef="let element"> {{element.proc_b}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="mikrobiologia">
        <th mat-header-cell *matHeaderCellDef> Mikrobiologia </th>
        <td mat-cell *matCellDef="let element"> {{element.mikrobiologia}} </td>
      </ng-container>
      <ng-container matColumnDef="somatyka">
        <th mat-header-cell *matHeaderCellDef> Komórki somatyczne </th>
        <td mat-cell *matCellDef="let element"> {{element.somatyka}} </td>
      </ng-container>
      <ng-container matColumnDef="krioskopia">
        <th mat-header-cell *matHeaderCellDef> Krioskopia </th>
        <td mat-cell *matCellDef="let element"> {{element.krioskopia}} </td>
      </ng-container>
      <ng-container matColumnDef="mocznik">
        <th mat-header-cell *matHeaderCellDef> Mocznik </th>
        <td mat-cell *matCellDef="let element"> {{element.mocznik}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>