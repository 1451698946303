<div [class.larger]="style.getFontSize('larger')" [class.largest]="style.getFontSize('largest')"
  [class.contrast]="style.getContrast()">
  <div class="logo-top">
    <div _ngcontent-c2="" class="text-center example-toolbar" id="logo">
      <img _ngcontent-c2="" alt="Responsive image" class="img-fluid center" src="assets/images/logo2.png"
        style="max-height: 70px; box-shadow: none ">
    </div>
  </div>
  <div class="logo-top">
      <div class="text-center example-toolbar" id="logo2">
        <img _ngcontent-c2="" alt="Responsive image" class="img-fluid center" src="assets/images/logo_bierun2.png"
          style="max-height: 300px; box-shadow: none ">
      </div>
    </div>
   
  <div class="card" style="margin-top: 3%;">

    <h5 class="card-header info-color white-text text-center py-3" style="background-color: #829ed8">
      <strong>Panel logowania</strong>
    </h5>
    <!--Card content-->
    <div class="card-body px-lg-5 pt-0">

      <!-- Form -->
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="text-center" style="color: #757575;margin-top: 5%;">

        <!-- Email -->
        <div class="md-form">
          <input type="text" id="materialLoginFormEmail" autocomplete="on" formControlName="username" placeholder="Login"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control">
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Pole login jest wymagane</div>
          </div>
        </div>

        <!-- Password -->
        <div class="md-form">
          <input type="password" formControlName="password" autocomplete="on" id="materialLoginFormPassword" placeholder="Hasło"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control">
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Pole hasło jest wymagane</div>
          </div>
        </div>

        <div class="d-flex justify-content-around">

          <div>
            <!-- Forgot password -->
            <a href="reset_hasla">Przypomnij hasło</a>
          </div>
        </div>

        <!-- Sign in button -->
        <button [disabled]="loading" class="btn btn-outline-primary btn-rounded btn-block my-4 waves-effect z-depth-0"
          type="submit">Zaloguj</button>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>


        <!-- Social login -->


      </form>
      <!-- Form -->

    </div>
   
  </div>
  <footer>
      <img  class="img-fluid center" src="assets/images/copyright.png" alt="" style="max-width: 30%;">

  </footer>
</div>