<div [class.larger]="style.getFontSize('larger')" [class.largest]="style.getFontSize('largest')"
  [class.contrast]="style.getContrast()">

  <div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <div class="logo-top" *ngIf="!mobileQuery.matches">
      <!-- <div class="text-center example-toolbar" id="logo">
        <img _ngcontent-c2="" alt="Responsive image" class="img-fluid center" src="assets/images/logo_bierun2.png"
          style="max-height: 300px; box-shadow: none ">
      </div> -->
      <div _ngcontent-c2="" class="text-center example-toolbar" id="logo">
        <img _ngcontent-c2="" alt="Responsive image" class="img-fluid center" src="assets/images/logo2.png"
          style="max-height: 70px; box-shadow: none ">
      </div>
    </div>
    <mat-toolbar color="primary" class="example-toolbar">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <img _ngcontent-c2="" alt="Responsive image" class="img-fluid center" src="assets/images/logo_bierun2.png"
          style="    max-height: 95%; box-shadow: none ">
      <button mat-button class="right-side-menu" [matMenuTriggerFor]="menu">{{username}} <mat-icon>settings</mat-icon>
      </button>
      
      <mat-menu #menu="matMenu" style="background: white;">
        <button mat-menu-item style="color:black" [routerLink]="['change_password']">
          <mat-icon style="color:black">vpn_key</mat-icon>Zmień hasło
        </button>
        <button mat-menu-item (click)="logout()" style="color:black">
          <mat-icon style="color:black">clear</mat-icon>Wyloguj
        </button>
      </mat-menu>
    </mat-toolbar>

    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 55 : 0">
      <mat-sidenav class="sidenav" [style.marginTop.px]="mobileQuery.matches ? 55 : 0" #snav
        [opened]="!mobileQuery.matches" [mode]="'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="0">
        <mat-nav-list class="nav-list">

          <a mat-list-item style="color: white; margin: 1% auto;line-height: 0.9;" routerLinkActive="active-list-item"
            [routerLink]="['wyniki_badan_surowca']">
            Wyniki badań surowca
          </a>
          <a mat-list-item style="color: white; margin: 1% auto;" routerLinkActive="active-list-item"
            [routerLink]="['badania_za_okres']">
            Badania za okres
          </a>
          <a mat-list-item style="color: white; margin: 1% auto;" routerLinkActive="active-list-item"
            [routerLink]="['dostawy_surowca']">
            Dostawy surowca
          </a>
          <a mat-list-item style="color: white; margin: 1% auto;" routerLinkActive="active-list-item"
            [routerLink]="['historia_dostaw']">
            Rozliczenie dostaw
          </a>
          <a mat-list-item style="color: white; margin: 1% auto;" routerLinkActive="active-list-item"
            [routerLink]="['rejestr_potracen']">
            Rejestr potrąceń
          </a>
          <a mat-list-item style="color: white; margin: 1% auto;" routerLinkActive="active-list-item"
            [routerLink]="['dokumenty']">
            Dokumenty
          </a>

        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content>
        <router-outlet></router-outlet>

      </mat-sidenav-content>
    </mat-sidenav-container>
    <footer>
      <img  class="img-fluid center" src="assets/images/copyright.png" alt="" style="max-width: 30%;">

  </footer>
  </div>


</div>