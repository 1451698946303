<div class="container">
  <mat-toolbar class="py-4 color">
    <span class="title-center">Rozliczenie dostaw</span>
  </mat-toolbar>
  <!-- <mat-form-field>
    <mat-label>Rok</mat-label>  
    <input matInput placeholder="Wybierz okres" [satDatepicker]="picker" (dateChange)="changeDataRange($event)"
      [value]="date" (ngModel)="date">
    <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
  </mat-form-field> -->
  <div class='row'>
    <div class='col-md-5'>
      <div class='col-xs-12'>
        <mat-form-field>
          <input matInput [matDatepicker]="dp" placeholder="Okres od" [formControl]="startDate">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event,startDate)"
            (monthSelected)="chosenMonthHandler($event, dp,startDate)" panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin-left:1%">
          <input matInput [matDatepicker]="dp1" placeholder="do" [formControl]="endDate">
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker #dp1 startView="multi-year" (yearSelected)="chosenYearHandler($event,endDate)"
            (monthSelected)="chosenMonthHandler($event, dp1,endDate)" panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div class="sum">
        <p>Łączna wartość dostaw brutto: {{ sumDeliveryCharge | number : '1.2-2' }} zł</p>
        <p>Łączna ilość mleka: {{ sumMilk }} litrów </p>
      </div>
    </div>
    <div class='col-md-7'>
      <app-chart *ngIf="chartData.length" [chartData]="chartData" [labels]="chartLabels"></app-chart>
    </div>
  </div>
  <button (click)="captureScreen()" mat-button class="pull-right">
    <mat-icon>print</mat-icon>
  </button>
  <div id="contentToConvert" #table>
    <table mat-table [dataSource]="dostawyList" class="mat-elevation-z8" style="margin: 2% 0">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="data_dostawy">
        <th mat-header-cell *matHeaderCellDef> Okres </th>
        <td mat-cell *matCellDef="let element;let last = last">
          {{element.data_dostawy ? [element.data_dostawy] : 'Razem' }} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="litry">
        <th mat-header-cell *matHeaderCellDef> Litry ogółem </th>
        <td mat-cell *matCellDef="let element;let last = last">
          {{element.litry}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="proc_t">
        <th mat-header-cell *matHeaderCellDef> Zawartość tłuszczu </th>
        <td mat-cell *matCellDef="let element;let last = last">
          {{element.proc_t| number : '1.2-2'}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="sc_netto">
        <th mat-header-cell *matHeaderCellDef> Śr. cena netto </th>
        <td mat-cell *matCellDef="let element;let last = last">
          {{element.sc_netto | number : '1.2-2'}} </td>
      </ng-container>
      <ng-container matColumnDef="netto">
        <th mat-header-cell *matHeaderCellDef> Wartość netto </th>
        <td mat-cell *matCellDef="let element;let last = last">
          {{element.netto | number : '1.2-2'}} </td>
      </ng-container>
      <ng-container matColumnDef="brutto">
        <th mat-header-cell *matHeaderCellDef> Wartość brutto </th>
        <td mat-cell *matCellDef="let element;let last = last">
          {{element.brutto | number : '1.2-2'}} </td>
      </ng-container>
      <ng-container matColumnDef="nr_fakt">
        <th mat-header-cell *matHeaderCellDef> Numer faktury </th>
        <td mat-cell *matCellDef="let element;let last = last">
          {{element.nr_fakt}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>