<div class="container">
  <mat-toolbar class="py-4 color">
    <span class="title-center">Dostawy za okres</span>
  </mat-toolbar>
  <div class='row'>
    <div style="padding-left: 15px">
      <mat-form-field>
        <mat-label>Rok</mat-label>
        <mat-select [(ngModel)]="selectedYear" name="year" id="year" (selectionChange)="refreshData($event)">
          <mat-option *ngFor="let year of years" [value]="year.value">
            {{year.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="padding-left: 15px">
      <mat-form-field>
        <mat-label>Miesiąc</mat-label>
        <mat-select [(ngModel)]="selectedMonth" name="month" id="month" (selectionChange)="refreshData($event)">
          <mat-option *ngFor="let month of months" [value]="month.value">
            {{month.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <table mat-table [dataSource]="dostawyList" class="mat-elevation-z8" style="margin: 2% 0">

    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="data_dost">
      <th mat-header-cell *matHeaderCellDef> Data dostawy </th>
      <td mat-cell *matCellDef="let element;let last = last" [ngClass]="last || !element.data_dost ?  'last' : ''">
        {{element.data_dost ? [element.data_dost | date: 'dd.MM.yyyy'] : element.c_data_dost }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="ilosc_zatw">
      <th mat-header-cell *matHeaderCellDef> Ilość zatwierdzona </th>
      <td mat-cell *matCellDef="let element;let last = last" [ngClass]="last || !element.data_dost ?  'last' : ''">
        {{element.ilosc_zatw}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="ilosc_odczyt">
      <th mat-header-cell *matHeaderCellDef> Ilość odczytana z cysterny </th>
      <td mat-cell *matCellDef="let element;let last = last" [ngClass]="last || !element.data_dost ?  'last' : ''">
        {{element.ilosc_odczyt}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="dost_godzina">
      <th mat-header-cell *matHeaderCellDef> Godzina dostawy </th>
      <td mat-cell *matCellDef="let element;let last = last" [ngClass]="last || !element.data_dost ?  'last' : ''">
        {{element.dost_godzina}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>