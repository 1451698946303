import { Component, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { User } from './models/user';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUser: User;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  title = 'PortalDM';
  constructor(
    private router: Router,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private authenticationService: AuthenticationService
  ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      this.mobileQuery = media.matchMedia('(max-width: 500px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }
  logout() {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
  }
  ngOnInit() {
    localStorage.getItem('larger') !== null ? null : localStorage.setItem('larger', 'false');
    localStorage.getItem('largest') !== null ? null : localStorage.setItem('largest', 'false');
    localStorage.getItem('contrast') !== null ? null : localStorage.setItem('contrast', 'false');
    if (localStorage.getItem('contrast') === 'true' ) {
      this.renderer.removeClass(document.body, 'bg');
      this.renderer.addClass(document.body, 'contrast-bg');
    } 
  }
  largerFont() {
    localStorage.setItem('larger', 'true');
    localStorage.setItem('largest', 'false');
  }
  largestFont() {
    localStorage.setItem('larger', 'false');
    localStorage.setItem('largest', 'true');
  }
  normalFont() {
    localStorage.setItem('larger', 'false');
    localStorage.setItem('largest', 'false');
  }
  contrast() {
    if ( localStorage.getItem('contrast') === 'true' ) {
      localStorage.setItem('contrast', 'false');
      this.renderer.removeClass(document.body, 'contrast-bg');
      this.renderer.addClass(document.body, 'bg');
    } else {
      localStorage.setItem('contrast', 'true');
      this.renderer.removeClass(document.body, 'bg');
      this.renderer.addClass(document.body, 'contrast-bg');
    }
  }
}
