import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from './password-validation';
import { RestService } from '../services/rest.service';
import { AuthenticationService } from '../services/authentication.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  error = '';
  constructor(
    fb: FormBuilder,
    private restApiService: RestService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar
    ) {
    this.form = fb.group({
    // define your control in you form
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: PasswordValidation.MatchPassword // your validation method
    });
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  isValid(): boolean {
    if (this.form.controls.confirmPassword.errors) {
      return this.form.controls.confirmPassword.errors.MatchPassword;
    } else {
      return false;
    }
  }
  get f() { return this.form.controls; }
  ngOnInit() {
  }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      console.log('błąd');
      return;
    }
    const body = {
      login : this.authenticationService.getUserName(),
      oldpass : this.form.get('oldPassword').value,
      newpass : this.form.get('newPassword').value
    };
    this.restApiService.callRestApi('/rpc/change_password', body)
    .subscribe(
      data => {
        //console.log(data);
        this.openSnackBar('Hasło zostało zmienione', '');
        this.router.navigate(['home']);
      },
      error => {
        console.log(error);
        this.error = error.error.message;
      });
  }



}
