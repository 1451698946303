import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeStyleService } from '../services/change.style.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  currentUser: User;
  mobileQuery: MediaQueryList;
  username: string;
// tslint:disable-next-line: variable-name
  private _mobileQueryListener: () => void;
  @ViewChild('snav') sidenav: MatSidenav;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public style: ChangeStyleService
  ) {
      //this.router.url === '/home' ? this.sidenav.opened
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      this.username = this.authenticationService.getUserName();
      this.mobileQuery = (media.matchMedia('(max-width: 650px) ,(max-height: 650px)'));
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }

  logout() {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  isMobile(){
    return this.mobileQuery.matches ? 'false' : 'true';
  }
}
