<div class="container">
    <mat-toolbar class="py-4 color">
        <span class="title-center">Dokumenty do pobrania</span>
      </mat-toolbar>
  <table class="table">
    <tbody>
      <tr *ngFor="let dokument of dokumentyList">
        <td><img src="assets/icopdf.png"></td>
        <td>
          <div class="row">
            <span>Nazwa pliku: </span><b> {{ dokument.nazwa_pliku}}</b>
          </div>
          <div class="row">
            <span>Data utworzenia: </span> {{ dokument.data_utworzenia | date: 'dd.MM.yyyy hh:mm'}}
          </div>
        </td>
        <td>
          <button class="btn btn-primary" (click)="download_file(dokument.nr_id,dokument.nazwa_pliku)">Pobierz</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>