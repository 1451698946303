
<div class="container">
    <h5 class="info-color white-text text-center py-3" style="background-color: #ffffff" >
        <strong>Panel zmiany hasła</strong>
      </h5>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
          <label for="oldPassword">Stare  hasło</label>
          <input [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }"
            autocomplete="on" 
            type="password" 
            id="oldPassword" 
            class="form-control"
            formControlName="oldPassword" 
            [formGroup]="form">    
        </div>
        <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
          <div *ngIf="f.oldPassword.errors.required">Pole login jest wymagane</div>
        </div>
    <div class="form-group">
      <label for="newPassword">Nowe hasło</label>
      <input [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }"
        autocomplete="on"
        type="password" 
        id="newPassword" 
        class="form-control" 
        formControlName="newPassword" 
        [formGroup]="form">    
    </div>
    <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
      <div *ngIf="f.newPassword.errors.required">Pole login jest wymagane</div>
    </div>
    <div class="form-group">
      <label for="confirmPassword">Powtórz hasło</label>
      <input [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
        autocomplete="on"
        type="password" 
        class="form-control" 
        id="confirmPassword"
        formControlName="confirmPassword" 
        [formGroup]="form">
    </div>
    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
      <div *ngIf="f.confirmPassword.errors.required">Pole login jest wymagane</div>
    </div>
    <div class="alert alert-danger" *ngIf="isValid()">Hasła różnią się od siebie</div>
    <div *ngIf="error" class="alert alert-danger">{{error}}</div>
      <input type="submit" class="btn btn-primary" [disabled]="isValid()" value="Zapisz" >
  </form>
</div>