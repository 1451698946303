<div class="container">
    <mat-toolbar class="py-4 color">
      <span class="title-center">Rejestr potrąceń</span>
    </mat-toolbar>
    <table mat-table [dataSource]="dostawyList" class="mat-elevation-z8" style="margin: 2% 0">
  
      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
  
      <!-- Position Column -->
      <ng-container matColumnDef="sksk_nazwa">
        <th mat-header-cell *matHeaderCellDef> Nazwa składnika </th>
        <td mat-cell *matCellDef="let element;let first = first;let last = last" [ngClass]="last  ?  'last' : ''">
          {{element.sksk_nazwa ? [element.sksk_nazwa] : 'Razem' }} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="wartosc_potr">
        <th mat-header-cell *matHeaderCellDef> Wartość potrącenia [zł] </th>
        <td mat-cell *matCellDef="let element;let first = first;let last = last;let last = last" [ngClass]="last  ?  'last' : ''">
          {{element.wartosc_potr | number : '1.2-2'}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="rata">
        <th mat-header-cell *matHeaderCellDef> Rata potrącenia [zł] </th>
        <td mat-cell *matCellDef="let element;let first = first;let last = last" [ngClass]="last  ?  'last' : ''">
          {{element.rata| number : '1.2-2'}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="data_rozp">
        <th mat-header-cell *matHeaderCellDef> Data rozpoczęcia spłat </th>
        <td mat-cell *matCellDef="let element;let first = first;let last = last" [ngClass]="last  ?  'last' : ''">
          {{element.data_rozp | date: 'dd.MM.yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="splata">
        <th mat-header-cell *matHeaderCellDef> Spłata całkowita </th>
        <td mat-cell *matCellDef="let element;let first = first;let last = last" [ngClass]="last  ?  'last' : ''">
          {{element.splata | number : '1.2-2'}} </td>
      </ng-container>
      <ng-container matColumnDef="saldo">
        <th mat-header-cell *matHeaderCellDef> Saldo </th>
        <td mat-cell *matCellDef="let element;let first = first;let last = last" [ngClass]="last  ?  'last' : ''">
          {{element.saldo | number : '1.2-2'}} </td>
      </ng-container>
      <ng-container matColumnDef="rata_biezaca">
        <th mat-header-cell *matHeaderCellDef> Rata </th>
        <td mat-cell *matCellDef="let element;let first = first;let last = last" [ngClass]="last  ?  'last' : ''">
          {{element.rata_biezaca}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>