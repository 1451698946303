import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
registerLocaleData(localePl, 'pl');
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { JwtInterceptor } from './services/jwt.interceptor';
import { MatToolbarModule, MatButtonModule, MatCardModule, MatInputModule, MatDialogModule, MatTableModule, MatMenuModule, MatIconModule, MatProgressSpinnerModule, MatSidenavModule, MatListModule, MatSnackBarModule, MatSelectModule, MAT_DATE_LOCALE, MatDatepickerModule, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { SatDatepickerModule } from 'saturn-datepicker';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BadaniaBiezaceComponent } from './badania-biezace/badania-biezace.component';
import { BadaniaZaOkresComponent } from './badania-za-okres/badania-za-okres.component';
import { DostawySurowcaComponent } from './dostawy-surowca/dostawy-surowca.component';
import { HistoriaDostawComponent } from './historia-dostaw/historia-dostaw.component';
import { RejestPotracenComponent } from './rejest-potracen/rejest-potracen.component';
import { DokumentyComponent } from './dokumenty/dokumenty.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ChartsModule } from 'ng2-charts';
import { ChartComponent } from './chart/chart.component';
import { PdfTopComponent } from './pdf-top/pdf-top.component';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DefaultComponent } from './default/default.component';

export const MY_FORMATS = {
   parse: {
     dateInput: 'MM.YYYY',
   },
   display: {
     dateInput: 'MM.YYYY',
     monthYearLabel: 'MMM YYYY',
     dateA11yLabel: 'LL',
     monthYearA11yLabel: 'MMMM YYYY',
   },
 };

@NgModule({
   declarations: [
      AppComponent,
      LoginComponent,
      HomeComponent,
      ChangePasswordComponent,
      BadaniaBiezaceComponent,
      BadaniaZaOkresComponent,
      DostawySurowcaComponent,
      HistoriaDostawComponent,
      RejestPotracenComponent,
      DokumentyComponent,
      ResetPasswordComponent,
      ChartComponent,
      PdfTopComponent,
      DefaultComponent
   ],
   imports: [
      BrowserModule,
      ChartsModule,
      SatDatepickerModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      FormsModule,
      CommonModule,
      MatToolbarModule,
      MatSnackBarModule,
      MatButtonModule,
      MatCardModule,
      MatInputModule,
      MatDialogModule,
      MatSelectModule,
      MatTableModule,
      MatMenuModule,
      MatIconModule,
      HttpClientModule,
      MatProgressSpinnerModule,
      ReactiveFormsModule,
      MatSidenavModule,
      MatDatepickerModule,
      MatListModule,
      NgxCaptchaModule
   ],
   providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'},
      { provide: LOCALE_ID, useValue: 'pl-PL' },
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

   ],
   bootstrap: [
      AppComponent
   ],
   exports: [
      ChangePasswordComponent,
      BadaniaBiezaceComponent,
      BadaniaZaOkresComponent,
      DostawySurowcaComponent,
      HistoriaDostawComponent,
      RejestPotracenComponent,
      DokumentyComponent
   ],
   entryComponents: [
      PdfTopComponent
   ]
})
export class AppModule { }
