import {NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './services/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BadaniaBiezaceComponent } from './badania-biezace/badania-biezace.component';
import { BadaniaZaOkresComponent } from './badania-za-okres/badania-za-okres.component';
import { DostawySurowcaComponent } from './dostawy-surowca/dostawy-surowca.component';
import { HistoriaDostawComponent } from './historia-dostaw/historia-dostaw.component';
import { RejestPotracenComponent } from './rejest-potracen/rejest-potracen.component';
import { DefaultComponent } from './default/default.component';
import { DokumentyComponent } from './dokumenty/dokumenty.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PdfTopComponent } from './pdf-top/pdf-top.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'change_password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
      },
      {
        path: '',
        //redirectTo: 'wyniki_badan_surowca',
        //pathMatch: 'full',
        component: DefaultComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'wyniki_badan_surowca',
        component: BadaniaBiezaceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'badania_za_okres',
        component: BadaniaZaOkresComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'dostawy_surowca',
        component: DostawySurowcaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'historia_dostaw',
        component: HistoriaDostawComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'rejestr_potracen',
        component: RejestPotracenComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'dokumenty',
        component: DokumentyComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'naglowek',
        component: PdfTopComponent,
        canActivate: [AuthGuard]
      }
    ],
  },
  {
      path: 'login',
      component: LoginComponent
  },
  {
      path: 'reset_hasla',
      component: ResetPasswordComponent
  },

  // otherwise redirect to home
  { path: '**', redirectTo: 'home' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }