<!-- <div class="row" style="max-width: 100%" >
  <div class="col-xl-12" style="margin: auto">
    <div *ngIf="isVisible">
      <div style="display: block">
        <canvas baseChart
                [datasets]="lineChartData"
                [labels]="chartLabels"
                [legend]="chartLegend"
                [options]="chartType == 'bar' ? barChartOptions : pieChartOptions"
                [chartType]="chartType"></canvas>
      </div>
    </div>
  </div>
</div> -->
<div class="flex-item">
  <div style="display: block; ">
  <canvas baseChart height="100px"
              [datasets]="chartData"
              [labels]="labels"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)"></canvas>
  </div>
</div>
