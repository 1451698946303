import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    private url = 'http://212.106.129.242:3000';
    public currentUser: Observable<User>;
    helper = new JwtHelperService();

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        const body = {
            email: username,
            pass: password
        };
        return this.http.post<any>(this.url + '/rpc/login', body)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user[0] && user[0].token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user[0]));
                    this.currentUserSubject.next(user[0]);
                }

                return user;
            }));
    }
    getUserName(): string {
        return this.helper.decodeToken(this.currentUserSubject.value.token).email;
    }
    getNrDostawcy(): string {
        return this.helper.decodeToken(this.currentUserSubject.value.token).skds_kod;
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
