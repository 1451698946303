import { Component, OnInit, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef } from '@angular/core';
import { RestService } from '../services/rest.service';
import { BadaniaBiezace } from '../models/badania.biezace';
import { AuthenticationService } from '../services/authentication.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from 'saturn-datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { PdfTopComponent } from '../pdf-top/pdf-top.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-badania-biezace',
  templateUrl: './badania-biezace.component.html',
  styleUrls: ['./badania-biezace.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class BadaniaBiezaceComponent implements OnInit {
  displayedColumns: string[] = ['data_badania', 'proc_t', 'proc_b', 'mikrobiologia', 'somatyka','krioskopia','mocznik'];
  badaniaList: BadaniaBiezace[] = [];
  date;
  startDate;
  endDate;
  selectedYear = new Date().getFullYear().toString();
  constructor(
    private restApiService: RestService,
    private authenticationService: AuthenticationService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  ngOnInit() {
    this.getInitData();
  }

  changeDataRange(event) {
    this.startDate = event.value.begin.format('DD.MM.YYYY');
    this.endDate = event.value.end.format('DD.MM.YYYY');
    this.getInitData();
  }

  getInitData() {
    let nr_dostawcy = this.authenticationService.getNrDostawcy();
    if (nr_dostawcy) {
      const body = {
        p_nr_dostawcy: nr_dostawcy,
        p_badanie_od: this.startDate ? this.startDate : '01.01.' + this.selectedYear,
        p_badanie_do: this.endDate ? this.endDate : '31.12.' + this.selectedYear,
      };
      this.restApiService.callRestApi('/rpc/get_badania_biezace', body)
        .subscribe(
          (data: BadaniaBiezace[]) => {
            this.badaniaList = data;

          },
          error => {
            console.log(error);
          });
    }
  }
  public captureScreen() {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(PdfTopComponent)
      .create(this.injector);
    componentRef.instance.okres = (this.startDate && this.endDate) ? this.startDate + ' - ' + this.endDate : this.selectedYear;
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    let data = document.getElementById('contentToConvert');
    let clone = <HTMLElement>data.cloneNode(true);
    clone.insertBefore(domElem, clone.firstChild);
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write( document.head.innerHTML );
    WindowPrt.document.write(clone.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();

    //   html2canvas(data,{
    //     onclone: function(doc) {
    //       let data =doc.getElementById('contentToConvert');
    //       data.insertBefore(domElem,data.firstChild)
    //     }
    //   }).then(canvas => {
    //     const imgWidth = 188;
    //     const pageHeight = 225;
    //     const imgHeight = canvas.height * imgWidth / canvas.width;
    //     console.log(imgHeight)
    //     let heightLeft = imgHeight;
    //     const contentDataURL = canvas.toDataURL('image/jpeg');
    //     const pdf = new jspdf('p', 'mm', 'a4');
    //     let position = 10;
    //     pdf.addImage(contentDataURL, 'JPG', 10, position, imgWidth, imgHeight);

    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = (heightLeft - imgHeight);
    //       pdf.addPage();
    //       pdf.addImage(contentDataURL, 'JPG', 10, position, imgWidth, imgHeight);
    //       heightLeft -= pageHeight;
    //     }
    //     pdf.save('MYPdf.pdf');
    //   })
     }

  }
