import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pdf-top',
  templateUrl: './pdf-top.component.html',
  styleUrls: ['./pdf-top.component.scss']
})
export class PdfTopComponent implements OnInit {
  @Input('okres') okres;
  constructor() { }

  ngOnInit() {
  }

}
