import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest.service';
import { DostawySurowca } from '../models/dostawy.surowca';
import { AuthenticationService } from '../services/authentication.service';

export interface Years {
  value: string;
  viewValue: string;
}

export interface Months {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dostawy-surowca',
  templateUrl: './dostawy-surowca.component.html',
  styleUrls: ['./dostawy-surowca.component.scss']
})
export class DostawySurowcaComponent implements OnInit {

  displayedColumns: string[] = ['data_dost', 'ilosc_zatw', 'ilosc_odczyt', 'dost_godzina'];
  dostawyList: DostawySurowca[] = [];
  selectedYear: string;
  selectedMonth: string;
  years: Years[] = [];
  months: Months[] = [
    { value: '01', viewValue: '01' },
    { value: '02', viewValue: '02' },
    { value: '03', viewValue: '03' },
    { value: '04', viewValue: '04' },
    { value: '05', viewValue: '05' },
    { value: '06', viewValue: '06' },
    { value: '07', viewValue: '07' },
    { value: '08', viewValue: '08' },
    { value: '09', viewValue: '09' },
    { value: '10', viewValue: '10' },
    { value: '11', viewValue: '11' },
    { value: '12', viewValue: '12' }
  ];
  constructor(
    private restApiService: RestService,
    private authenticationService: AuthenticationService
  ) {
    this.years = this.getYearsList();
    this.selectedYear = new Date().getFullYear().toString();
    this.selectedMonth = this.months[new Date().getMonth()].value;
    this.getInitData(this.selectedYear, this.selectedMonth);
  }

  ngOnInit() {
  }

  getInitData(p_rok: string, p_miesiac: string) {
    let p_nr_dostawcy = this.authenticationService.getNrDostawcy();
    let p_okres = p_rok + '.' + p_miesiac;
    if (p_nr_dostawcy) {
      const body = {
        p_nr_dostawcy,
        p_okres
      };
      this.restApiService.callRestApi('/rpc/get_dostawy_surowca', body)
        .subscribe(
          (data: DostawySurowca[]) => {
            this.dostawyList = data;
          },
          error => {
            console.log(error);
          });
    }
  }

  getYearsList(): Years[] {
    const date = new Date();
    let year = date.getFullYear();
    let result: Years[] = [];
    for (let i = 0; i < 3; i++) {
      result.push({
        value: year.toString(),
        viewValue: year.toString()
      });
      year--;

    }
    return result;

  }
  refreshData(event) {
    const month = event.source.id === 'month' ? event.value : this.selectedMonth;
    const year = event.source.id === 'year' ? event.value : this.selectedYear;
    this.getInitData(year, month);
  }

}
