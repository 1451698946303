import { Component, OnInit } from '@angular/core';
import { BadaniaZaOkres, WynikiBadanZaOkres } from '../models/badania.za.okres';
import { RestService } from '../services/rest.service';
import { AuthenticationService } from '../services/authentication.service';


export interface Years {
  value: string;
  viewValue: string;
}

export interface Months {
  value: string;
  viewValue: string;
}
export interface RodzajeBadan {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-badania-za-okres',
  templateUrl: './badania-za-okres.component.html',
  styleUrls: ['./badania-za-okres.component.scss'],

})
export class BadaniaZaOkresComponent implements OnInit {

  displayedColumns: string[] = ['w_srednie', 'i_polowa', 'ii_polowa'];
  badaniaList: BadaniaZaOkres[] = [];
  proc_t: WynikiBadanZaOkres[] = [];
  proc_b: WynikiBadanZaOkres[] = [];
  somatyka: WynikiBadanZaOkres[] = [];
  mikrobiologia: WynikiBadanZaOkres[] = [];
  years: Years[] = [];
  columnIndex: number[] = [
    1,
    2,
    3,
    4,
    5,
    6
  ];
  months: Months[] = [
    { value: '01', viewValue: '01' },
    { value: '02', viewValue: '02' },
    { value: '03', viewValue: '03' },
    { value: '04', viewValue: '04' },
    { value: '05', viewValue: '05' },
    { value: '06', viewValue: '06' },
    { value: '07', viewValue: '07' },
    { value: '08', viewValue: '08' },
    { value: '09', viewValue: '09' },
    { value: '10', viewValue: '10' },
    { value: '11', viewValue: '11' },
    { value: '12', viewValue: '12' }
  ];
  rodzajeBadan: RodzajeBadan[] = [
    { value: 'proc_t', viewValue: 'Badania % tłuszczu' },
    { value: 'proc_b', viewValue: 'Badania % białka' },
    { value: 'mikrobiologia', viewValue: 'Mikrobiologia' },
    { value: 'somatyka', viewValue: 'Komórki somatyczne' }
  ];
  selectedYear: string;
  selectedMonth: string;
  constructor(
    private restApiService: RestService,
    private authenticationService: AuthenticationService
  ) {
    this.years = this.getYearsList();
    this.selectedYear = new Date().getFullYear().toString();
    this.selectedMonth = this.months[new Date().getMonth()].value;
    this.getInitData(this.selectedYear, this.selectedMonth);
  }

  ngOnInit() {
  }

  getInitData(p_rok: string, p_miesiac: string) {
    let p_nr_dostawcy = this.authenticationService.getNrDostawcy();
    if (p_nr_dostawcy) {
      const body = {
        p_nr_dostawcy,
        p_rok,
        p_miesiac
      };
      this.restApiService.callRestApi('/rpc/get_badania_za_okres', body)
        .subscribe(
          (data: BadaniaZaOkres[]) => {
            this.badaniaList = data;
          },
          error => {
            console.log(error);
          });
    }
    this.getWynikiBadan(p_rok, p_miesiac, p_nr_dostawcy);
  }
  getWynikiBadan(p_rok: string, p_miesiac: string, p_nr_dostawcy: string): any {
    this.rodzajeBadan.forEach(badanie => {
      if (p_nr_dostawcy) {
        const body = {
          p_nr_dostawcy,
          p_typ: badanie.value,
          p_rok,
          p_miesiac
        };
        this.restApiService.callRestApi('/rpc/get_wyniki_badan_za_okres', body)
          .subscribe(
            (data: WynikiBadanZaOkres[]) => {
              this[badanie.value] = data;
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  getYearsList(): Years[] {
    const date = new Date();
    let year = date.getFullYear();
    let result: Years[] = [];
    for (let i = 0; i < 3; i++) {
      result.push({
        value: year.toString(),
        viewValue: year.toString()
      });
      year--;

    }
    return result;

  }
  refreshData(event) {
    const month = event.source.id === 'month' ? event.value : this.selectedMonth;
    const year = event.source.id === 'year' ? event.value : this.selectedYear;
    this.getInitData(year, month);
  }

}
