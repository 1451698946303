<div class="container">
  <mat-toolbar class="py-4 color">
    <span class="title-center">Badania za okres</span>
  </mat-toolbar>
  <div class='row'>
    <div style="padding-left: 15px">
      <mat-form-field>
        <mat-label>Rok</mat-label>
        <mat-select [(ngModel)]="selectedYear" name="year" id="year" (selectionChange)="refreshData($event)">
          <mat-option *ngFor="let year of years" [value]="year.value">
            {{year.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="padding-left: 15px" >
      <mat-form-field >
        <mat-label>Miesiąc</mat-label>
        <mat-select [(ngModel)]="selectedMonth" name="month" id="month" (selectionChange)="refreshData($event)">
          <mat-option *ngFor="let month of months" [value]="month.value">
            {{month.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <table class=" mat-elevation-z8 mat-table" *ngIf="badaniaList.length>0">
    <thead>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">Wyniki
          średnie za {{ badaniaList[0].miesiac }}.{{ badaniaList[0].rok }} </th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">I
          połowa</th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">II
          połowa</th>
      </tr>
    </thead>
    <tbody>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">Tłuszcz
        </th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].proc_t_1 }}</td>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].proc_t_2 }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">Białko
        </th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].proc_b_1 }}</td>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].proc_b_2 }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">
          Mikrobiologia (śr. geometryczna)</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].mikr_1 }}</td>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].mikr_2 }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">
          Somatyka (śr. geometryczna)</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].somatyczne_1 }}
        </td>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].somatyczne_2 }}
        </td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">Klasa
        </th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].klasa_1 }}</td>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted">{{ badaniaList[0].klasa_2 }}</td>
      </tr>
    </tbody>
  </table>
  <table class=" mat-elevation-z8 mat-table" *ngIf="badaniaList.length>0" style="margin: 2% 0">
    <thead>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="col"
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted first-column">Wyniki
          badań </th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted left">
        </th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">1</th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">2</th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">3</th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">4</th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">5</th>
        <th scope="col" class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted">6</th>
      </tr>
    </thead>
    <tbody>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" rowspan="2"
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit first-column">
          {{ rodzajeBadan[0].viewValue }}</th>
        <th
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit second-column bottom">
          data:</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted"
          *ngFor=" let index of columnIndex;  let i=index">
          {{ proc_t[i] ? [proc_t[i].data_badania | date: 'dd.MM.yyyy']: '-' }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit second-column">
          wyniki:</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted"
          *ngFor=" let index of columnIndex;  let i=index">{{ proc_t[i] ? proc_t[i].wynik_badania : '-' }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" rowspan="2"
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit first-column">
          {{ rodzajeBadan[1].viewValue }}</th>
        <th
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit second-column bottom">
          data:</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted"
          *ngFor=" let index of columnIndex;  let i=index">
          {{ proc_b[i] ? [proc_b[i].data_badania | date: 'dd.MM.yyyy']: '-' }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit second-column">
          wyniki:</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted"
          *ngFor=" let index of columnIndex;  let i=index">{{ proc_b[i] ? proc_b[i].wynik_badania : '-' }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" rowspan="2"
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit first-column">
          {{ rodzajeBadan[2].viewValue }}</th>
        <th
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit second-column bottom">
          data:</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted"
          *ngFor=" let index of columnIndex;  let i=index">
          {{ somatyka[i] ? [somatyka[i].data_badania | date: 'dd.MM.yyyy']: '-' }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit second-column">
          wyniki:</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted"
          *ngFor=" let index of columnIndex;  let i=index">{{ somatyka[i] ? somatyka[i].wynik_badania : '-' }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th scope="row" rowspan="2"
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit first-column">
          {{ rodzajeBadan[3].viewValue }}</th>
        <th
          class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit second-column bottom">
          data:</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted"
          *ngFor=" let index of columnIndex;  let i=index">
          {{ mikrobiologia[i] ? [mikrobiologia[i].data_badania | date: 'dd.MM.yyyy']: '-' }}</td>
      </tr>
      <tr class="mat-header-row ng-star-inserted">
        <th class="mat-header-cell cdk-column-data_badania mat-column-data_badania ng-star-inserted fit second-column">
          wyniki:</th>
        <td class="mat-cell cdk-column-somatyka mat-column-somatyka ng-star-inserted"
          *ngFor=" let index of columnIndex;  let i=index">{{ mikrobiologia[i] ? mikrobiologia[i].wynik_badania : '-' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>