<div [class.larger]="style.getFontSize('larger')" [class.largest]="style.getFontSize('largest')"
  [class.contrast]="style.getContrast()">
  <div class="logo-top">
    <div _ngcontent-c2="" class="text-center example-toolbar" id="logo">
      <img _ngcontent-c2="" alt="Responsive image" class="img-fluid center" src="assets/images/logo2.png"
        style="max-height: 300px; box-shadow: none ">
    </div>
  </div>
  <div class="card" style="margin-top: 3%;" *ngIf="!linkSended">

    <h5 class="card-header info-color white-text text-center py-3" style="background-color: #829ed8">
      <strong>Formularz resetowania hasła</strong>
    </h5>
    <!--Card content-->
    <div class="card-body px-lg-5 pt-0">

      <!-- Form -->
      <form [formGroup]="resetPasswdForm" (ngSubmit)="onSubmit()" class="text-center"
        style="color: #757575;margin-top: 5%;">

        <!-- Email -->
        <div class="md-form">
          <input type="text" id="materialLoginFormEmail" autocomplete="on" formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control">
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Pole login jest wymagane</div>
            <div *ngIf="f.email.errors.email">Niepoprawny adres email</div>
          </div>
          <label for="materialLoginFormEmail">Adres email</label>
        </div>
        <div class="md-form " >
          <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()"
            (load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="useGlobalDomain" [size]="size"
            [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha" 
            >
          </ngx-recaptcha2>
          <div *ngIf="submitted && f.recaptcha.errors" style="display: block" class="invalid-feedback">
            <div *ngIf="f.recaptcha.errors.required">Pole login jest wymagane</div>
          </div>
        </div>
        <!-- Sign in button -->
        <button [disabled]="loading" class="btn btn-outline-primary btn-rounded btn-block my-4 waves-effect z-depth-0"
          type="submit">Wyślij link</button>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>


        <!-- Social login -->


      </form>
      <!-- Form -->

    </div>

  </div>
  <div class="card" style="padding: 1%;" *ngIf="linkSended" > 
    <h5 class="card-header info-color white-text text-center py-3" style="background-color: #829ed8">
      <strong>Hasło zostało zresetowane</strong>
    </h5>
    <h3><span style="color: white;">W ciągu kilku minut na podany adres email zostanie wysłana wiadomość z nowym hasłem.</span></h3>
  </div>
</div>